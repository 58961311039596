<template>
  <b-row class="match-height mx-auto">
    <b-col lg="6" class="mx-auto">
      <chage-password />
    </b-col>
    <!-- <b-col lg="6">
      <change-pin />
    </b-col> -->
    
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ChagePassword from '../components/ChangePassword.vue'
import ChangePin from '../components/ChangePin.vue'
export default {
  components: {
    BRow,
    BCol,

   ChagePassword,
   ChangePin,
  },
}
</script>