<template>
  <b-card-code
    title="Change Transaction Pin"
  >
  <validation-observer ref="simpleRules"  #default="{invalid}">
    <b-form @submit.prevent="validationForm" >
      <b-row>

        <!-- first name -->
           <b-col cols="12">
          <b-form-group
            label="Password"
            label-for="v-password"
          >
           <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required"
            >
            <b-form-input
              id="v-password"
              type="password"
                :state="errors.length > 0 ? false:null"
              v-model="password"
              placeholder="Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
       <b-col cols="12">
          <b-form-group
            label="New Pin"
          
            label-for="v-password"
          >
           <validation-provider
              #default="{ errors }"
              name="New Pin"
              vid="Pin"
              rules="required|digits:5"
            >
            <b-form-input
              id="newpin"
              type="password"
              :state="errors.length > 0 ? false:null"
                v-model="newpin"
              placeholder="New Pin"
            />
             <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
           
          </b-form-group>
        </b-col>

        <!-- mobile -->
        <b-col cols="12">
          <b-form-group
            label="Confirm New Pin"
            label-for="v-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm New Pin"
              vid="CPin"
              rules="required|digits:5|confirmed:Pin"
            >
            <b-form-input
              id="confirmnewpin"
              type="password"
                :state="errors.length > 0 ? false:null"
              v-model="confirmpin"
              placeholder="Confirm New Pin"
            />
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

       

       
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="invalid || load"
            class="mr-1"
          >
           <b-spinner v-if="load"
          small
          type="grow"
        />  
            Change Pin
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BSpinner, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, min, digits, confirmed,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    ToastificationContent,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
     ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        load: false,
     password : '',
     newpin: '',
     confirmpin: '',
      required,
      min,
      confirmed,
      digits,
     
    }
  },

  methods: {
                async change() {
      this.load = true;

     var data = await this.$store.dispatch('changepin', {password: this.password, newpin: this.newpin, newpin_confirmation: this.confirmpin, });  
  
if (data.status == true) {
  this.load = false;
 
  this.$swal({
        title: data.message,
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })



  
    
}else {

    console.log( Object.prototype.toString.call(data.message))
  if (data.object == true) {
       var data = JSON.parse(data.message)
       console.log(data);
    this.load = false;
    for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    




  }else {

this.load = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,
                    },
                  })

  }
}







       
   },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.change()
        }
      })
    },
  },
}
</script>
