<template>
  <b-card-code
    title="Change Password"
  >
  <validation-observer ref="simpleRules"  #default="{invalid}">
    <b-form @submit.prevent="validationForm">
      <b-row>

        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Password"
            label-for="v-password"
          >
           <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required"
            >
            <b-form-input
              id="v-password"
              type="password"
                :state="errors.length > 0 ? false:null"
              v-model="password"
              placeholder="Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
       <b-col cols="12">
          <b-form-group
            label="New Password"
            label-for="v-password"
          >
           <validation-provider
              #default="{ errors }"
              name="New Password"
              vid="Password"
              rules="required|min:8|password"
            >
            <b-form-input
              id="v-password"
              type="password"
               :state="errors.length > 0 ? false:null"
              v-model="newpassword"
              placeholder="Password"
            />
              <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
          </b-form-group>
        </b-col>

        <!-- mobile -->
        <b-col cols="12">
          <b-form-group
            label="Confirm New Password"
            label-for="v-password"
          >
           <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
            <b-form-input
              id="v-password"
              type="password"
                :state="errors.length > 0 ? false:null"
              v-model="confirmnewpassword"
              placeholder="Password"
            />
              <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
          </b-form-group>
        </b-col>

       

       
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid || load"
          >
            <b-spinner v-if="load"
          small
          type="grow"
        />  
            Change Password
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, confirmed,
} from '@validations'
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BSpinner, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BCardCode,
     ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    ToastificationContent,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {

    Ripple,
  },
  data() {
    return {
    load: false,
     required,
      min,
      confirmed,
      password: '',
      newpassword: '',
      confirmnewpassword: '',
    }
  },

    methods: {
          async change() {
      this.load = true;
       console.log(this.password)
       console.log(this.newpassword)
       console.log(this.confirmnewpassword)
       this.load = false
     var data = await this.$store.dispatch('changepassword', {password: this.password, newpassword: this.newpassword, newpassword_confirmation: this.confirmnewpassword, });  
  
if (data.status == true) {
  this.load = false;
  this.$swal({
        title: data.message,
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })



    this.$router.push({ name: 'login' });
    
}else {

    console.log( Object.prototype.toString.call(data.message))
  if (data.object == true) {
       var data = JSON.parse(data.message)
       console.log(data);
    this.load = false;
    for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    




  }else {

       this.load = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,
                    },
                  })

  }
}







       
   },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.change();
        }
      })
    },
  },

}
</script>
